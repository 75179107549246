import * as React from "react";
import './EventsPage.css';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton, TextField
} from "@mui/material";
import {Add, DeleteOutlined} from "@mui/icons-material";
import {useEffect, useState} from "react";
import { db } from "./firebase";
import {collection, onSnapshot, doc, deleteDoc, addDoc, Timestamp} from 'firebase/firestore';

export const EventsPage = () => {
    const [events, setEvents] = useState([]);
    const [open, setOpen] = useState(false);
    const [event, setEvent] = useState({
       title: '',
       info: undefined,
       date: Timestamp.now()
    });


    useEffect(() => {
        onSnapshot(collection(db, '/FireBrigades/dcV1nzDGgKBj9LWnwo9I/events'), (snapshot) => {
            setEvents(snapshot.docs.map(doc => ({
                key: doc.id,
                data: doc.data(),
                path: doc.ref.path
            })));
        });
    }, []);

    return (
        <div className="eventListContainer">
            <Dialog open={open}>
                <DialogTitle>Termin erstellen</DialogTitle>
                <DialogContent style={{width: 300}}>
                    <TextField
                        autoFocus
                        value={event.title}
                        onChange={(e) => setEvent({
                            ...event,
                            title: e.target.value
                        })}
                        style={{marginBottom: 20, marginTop: 10}}
                        label="Titel"/>
                    <br/>
                    <TextField
                        style={{marginBottom: 20}}
                        value={event.info}
                        onChange={(e) => setEvent({
                            ...event,
                            info: e.target.value
                        })}
                        label="Info"/>
                    <br/>
                    <label>Datum </label>
                    <input type="datetime-local" value={event.date.toDate()} onChange={(e) => setEvent({
                        ...event,
                        date: Timestamp.fromDate(new Date(e.target.value))
                    })}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Abbrechen</Button>
                    <Button onClick={() => {
                        addDoc(collection(db, '/FireBrigades/dcV1nzDGgKBj9LWnwo9I/events'), event)
                        setOpen(false)
                    }} variant="contained">Speichern</Button>
                </DialogActions>
            </Dialog>
            <header>
                <h1>Termine</h1>
                <span>
                    <Button variant="outlined" startIcon={<Add />} onClick={() => setOpen(true)}>
                        Termin erstellen
                    </Button>
                </span>
            </header>
            <main>
                { events.map(e => (<EventRow event={e} key={e.key}/>)) }
            </main>
        </div>
    )
}

export const EventRow = ({event}) => (
    <Card style={{marginBottom: 40}}>
        <CardContent>
            <h2>{event.data.title}</h2>
            <p>{event.data.info}</p>
        </CardContent>
        <CardActions>
            <IconButton onClick={ () => {
                // eslint-disable-next-line no-restricted-globals
                if (confirm("Termin löschen?")) {
                    deleteDoc(doc(db, event.path))
                }
            }}>
                <DeleteOutlined />
            </IconButton>
        </CardActions>
    </Card>
)


