import * as React from "react";
import './LoginPage.css';
import logo from './icon.png';
import {Button, Card, CardActions, CardContent, TextField} from "@mui/material";
import {useState} from "react";


export const LoginPage = ({callback}) => {

    // keep it stupid and simple
    const password = "ffhaag4680"
    const [input, setInput] = useState('');

    return (
        <div className="loginContainer">
            <img style={{height: 100, marginBottom: 30}}
                 src={logo}
                 alt="" />
            <Card>
                <CardContent>
                    <center>
                        <h1>INA</h1>
                    </center>
                    <TextField value={input}
                               onChange={(e) => setInput(e.target.value)}
                               style={{width: "100%"}}
                               variant="outlined"
                               type="password"
                               label="Passwort" />
                </CardContent>
                <CardActions>
                    <Button
                        onClick={() => {
                            if (input === password) {
                                callback()
                            }
                        }}>Anmelden</Button>
                </CardActions>
            </Card>
        </div>
    )
}
