import * as React from "react";
import {useState} from "react";
import {EventsPage} from "./EventsPage";
import {LoginPage} from "./LoginPage";

export const App = () => {
    const [auth, setAuth] = useState(false)

    return (
        <div className="App">
            { auth ? <EventsPage/> : <LoginPage callback={() => setAuth(true)}/> }
        </div>
    );
}
