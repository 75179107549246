// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA0ABmY898vFBlQJrezZOKFyQsPdYHS45M",
    authDomain: "mobile-alarmierung.firebaseapp.com",
    databaseURL: "https://mobile-alarmierung.firebaseio.com",
    projectId: "mobile-alarmierung",
    storageBucket: "mobile-alarmierung.appspot.com",
    messagingSenderId: "494561443877",
    appId: "1:494561443877:web:47d24046fd9b9b9793bfcc"
};

// Initialize Firebase
initializeApp(firebaseConfig);

// get the firestore DB
export const db = getFirestore();
